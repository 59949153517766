<template>
  <div v-if="!loading">
    <v-simple-table>
      <thead>
        <tr>
          <td
            v-for="(header, index) in headers"
            :key="`table-header-${index}`"
            :class="header.align ? `text-${header.align}` : ''"
            :style="`user-select: none; ${header.sortable ? 'cursor: pointer;' : ''}`"
            @click="header.sortable
              && (sortBy === header.value ? setOrderBy() : setSortBy(header.value))"
          >
            {{ header.text }}
            <v-icon
              v-if="header.sortable && sortBy === header.value"
              small
            >
              mdi-arrow-{{ orderBy === 'asc' ? 'down' : 'up' }}
            </v-icon>
          </td>
        </tr>
      </thead>
      <tbody v-if="items.length > 0">
        <tr
          v-for="(item, rowIndex) in items"
          :key="`row-${rowIndex}`"
        >
          <td
            v-for="(header, colIndex) in headers"
            :key="`row-${rowIndex}-column-${colIndex}`"
          >
            <slot
              v-if="$scopedSlots[header.value]"
              :props="item"
              :name="header.value"
            />
            <template v-else>
              {{ item[header.value] }}
            </template>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <slot
            v-if="$slots['data-empty']"
            name="data-empty"
          />
          <td
            v-else
            :colspan="headers.length"
          >
            <center>
              <v-icon v-if="error">
                mdi-exclamation
              </v-icon>
              {{ error ? errorMessage : noDataText }}
            </center>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-card
      class="mt-1"
      flat
    >
      <v-card-text>
        <v-row
          no-gutters
          justify="end"
          align="center"
        >
          <v-col
            xl="2"
            md="3"
            sm="5"
            cols="12"
          >
            <v-row
              no-gutters
              class="align-center"
            >
              <v-col
                cols="5"
              >
                <span class="caption">
                  ข้อมูลต่อหน้า :
                </span>
              </v-col>
              <v-col
                cols="7"
              >
                <v-select
                  v-model="itemPerPage"
                  filled
                  rounded
                  dense
                  hide-details
                  :items="[
                    10,
                    20,
                    30
                  ]"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            xl="1"
            md="2"
            sm="3"
            cols="12"
          >
            <p
              class="subtitle-1 paginate-info my-3"
            >
              {{ paginateInfo }}
            </p>
          </v-col>
          <v-col
            md="3"
            sm="4"
            cols="12"
          >
            <v-pagination
              v-model="page"
              color="blue"
              :total-visible="7"
              :length="totalPages"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
  <v-skeleton-loader
    v-else
    type="table-tbody"
  />
</template>

<script>
export default {
  name: 'BaseDataTable',
  props: {
    headers: {
      type: Array,
      default: () => ([]),
    },
    noDataText: {
      type: String,
      default: 'ไม่พบข้อมูล',
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    dataUrl: {
      type: String,
      default: '',
    },
    requestHeader: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      itemPerPage: 10,
      totalPages: 0,
      totalItems: 0,
      page: 1,
      items: [],
      sortBy: 'created_at',
      orderBy: 'desc',
      loading: false,
      error: false,
      errorMessage: '',
    };
  },
  computed: {
    httpHeader() {
      return {
        ...this.requestHeader,
        'Content-Type': 'application/json',
      };
    },
    paginateInfo() {
      return `${((this.page - 1) * this.itemPerPage) + 1} - ${(this.page * this.itemPerPage) > this.totalItems ? this.totalItems : (this.page * this.itemPerPage)} of ${this.totalItems}`;
    },
  },
  watch: {
    dataUrl: {
      async handler(value) {
        if (value) {
          const data = await this.requestToServer();
          this.setupData(data);
        }
      },
      immediate: true,
    },
    async filters() {
      const data = await this.requestToServer();
      this.setupData(data);
    },
    async orderBy() {
      const data = await this.requestToServer();
      this.setupData(data);
    },
    async sortBy() {
      const data = await this.requestToServer();
      this.setupData(data);
    },
    async page() {
      const data = await this.requestToServer();
      this.setupData(data);
    },
    async itemPerPage() {
      const data = await this.requestToServer();
      this.setupData(data);
    },
  },
  methods: {
    async refreshData() {
      const data = await this.requestToServer();
      this.setupData(data);
    },
    generateQueryUrl() {
      const queryParams = new URLSearchParams({
        ...this.filters,
        sort: this.sortBy,
        orderBy: this.orderBy,
        page: this.page,
        limit: this.itemPerPage,
      });
      return `${this.dataUrl}?${queryParams.toString()}`;
    },
    setOrderBy() {
      if (this.orderBy === 'asc') {
        this.orderBy = 'desc';
        return;
      }
      if (this.orderBy === 'desc') {
        this.orderBy = 'asc';
      }
    },
    setSortBy(key) {
      this.sortBy = key;
    },
    // eslint-disable-next-line consistent-return
    async requestToServer() {
      this.error = false;
      this.errorMessage = '';
      try {
        const response = await fetch(this.generateQueryUrl(), {
          method: 'GET',
          headers: {
            ...this.httpHeader,
          },
        });
        if (response.ok) {
          return response.json();
        }
        throw new Error('Request Failed');
      } catch (e) {
        this.error = true;
        this.errorMessage = e.message;
      }
    },
    setupData(data) {
      this.items = [...data.docs];
      this.totalItems = data.totalDocs;
      this.totalPages = data.totalPages;
      this.page = data.page;
    },
  },
};
</script>

<style>
.v-data-table tr:hover:not(.v-table__expanded__content) {
  background: none !important;
}
.v-pagination {
  justify-content: center;
  margin-bottom: 0px;
}
.paginate-info {
  margin-bottom: 0px!important;
  text-align: center;
}
</style>
